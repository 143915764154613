import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'
import {errorHandler} from '@/assets/scripts/scripts'
import Vue from 'vue'
import messages from '@/helpers/messages'
import {eventBus} from '@/main'

class PeopleAndCompaniesService {
  getAllPeoples() {
    store.commit('other/onChangeLoadingItems', true)

    const {orderBy, sortedBy, search, filter, paginationPage, isArchive} = store.state.peoples
    const {isActive} = store.state.filterPeoplesAndCompany

    let newParamsForPeoples = {
      page: paginationPage,
      include: 'organizations,priority_organization,organization_ownership,region,city,country',
    }
    if (orderBy !== null) newParamsForPeoples['orderBy'] = orderBy
    if (sortedBy !== null) newParamsForPeoples['sortedBy'] = sortedBy
    if (isActive !== null) newParamsForPeoples['is_active'] = isActive
    if (filter !== null) newParamsForPeoples['search'] = filter.main
    if (search !== '') newParamsForPeoples['find'] = search

    if (isArchive) newParamsForPeoples['only-trashed'] = `1`

    return $api
      .get('humans', {
        params: newParamsForPeoples,
        headers: authHeader(),
      })
      .then(async r => {
        await store.commit('peoples/getAllPeoples', r.data)
        store.commit('other/onChangeLoadingItems', false)
        eventBus.$emit('update-table-settings', [])
        return r.data.data
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  getPeoplesList() {
    let newParamsForPeoples = {
      include: 'organizations,priority_organization,organization_ownership',
      to: 'list',
      limit: '*',
    }
    return $api
      .get('humans', {
        params: newParamsForPeoples,
        headers: authHeader(),
      })
      .then(async r => {
        return r.data.data
      })
      .catch(e => {
        errorHandler(e)
        return e
      })
  }

  getPeoplesNoLimit() {
    let newParamsForPeoples = {
      include: 'event_position',
      limit: '*',
      search: 'show_in_event:1',
    }
    return $api
      .get('humans', {
        params: newParamsForPeoples,
        headers: authHeader(),
      })
      .then(async r => {
        return r.data.data
      })
      .catch(e => {
        errorHandler(e)
        return e
      })
  }

  getSelectedPeoples() {
    store.commit('other/onChangeLoadingItem', true)
    let selectedItems = store.state.peoples.selectedItemsForDelete

    let options = {
      searchFields: 'id:in',
    }

    if (selectedItems.length > 0) {
      options['search'] = 'id:'
    } else {
      // store.commit('sidebars/changeRightSidebar', false)
    }

    for (let i = 0; i < selectedItems.length; i++) {
      if (i !== selectedItems.length - 1) {
        options.search += selectedItems[i] + ','
      } else {
        options.search += selectedItems[i]
      }
    }

    return $api
      .get('humans', {
        params: options,
        headers: authHeader(),
      })
      .then(r => {
        store.commit('peoples/getSelectedPeoples', r.data)
        store.commit('other/onChangeLoadingItem', false)
        eventBus.$emit('update-table-settings', [])
      })
      .catch(e => {
        store.commit('peoples/getSelectedPeoples', {data: []})
        errorHandler(e)
      })
  }

  getAllCompanies() {
    store.commit('other/onChangeLoadingItems', true)

    const {orderBy, sortedBy, search, filter, paginationPage, isArchive} = store.state.companies
    const {isActive} = store.state.filterPeoplesAndCompany

    let newParams = {
      page: paginationPage,
      include: 'organizations,priority_organization,humans,organization_ownership',
    }

    if (orderBy !== null) newParams['orderBy'] = orderBy
    if (sortedBy !== null) newParams['sortedBy'] = sortedBy
    if (isActive !== null) newParams['is_active'] = isActive
    if (filter.main !== '') newParams['search'] = filter.main
    if (search !== '') newParams['find'] = search

    if (isArchive) newParams['only-trashed'] = `1`

    return $api
      .get('organizations', {
        params: newParams,
        headers: authHeader(),
      })
      .then(data => {
        store.commit('companies/getAllCompanies', data.data)
        store.commit('other/onChangeLoadingItems', false)
        eventBus.$emit('update-table-settings', [])
      })
  }

  getSelectedCompanies() {
    store.commit('other/onChangeLoadingItem', true)
    let selectedItems = store.state.companies.selectedItemsForDelete

    let options = {
      searchFields: 'id:in',
    }

    if (selectedItems.length > 0) {
      options['search'] = 'id:'
    } else {
      store.commit('sidebars/changeRightSidebar', false)
    }

    for (let i = 0; i < selectedItems.length; i++) {
      if (i !== selectedItems.length - 1) {
        options.search += selectedItems[i] + ','
      } else {
        options.search += selectedItems[i]
      }
    }
    return $api
      .get('organizations', {
        params: options,
        headers: authHeader(),
      })
      .then(r => {
        store.commit('companies/getSelectedCompanies', r.data)
        store.commit('other/onChangeLoadingItem', false)
        eventBus.$emit('update-table-settings', [])
      })
      .catch(e => {
        store.commit('companies/getSelectedCompanies', {data: []})
        errorHandler(e)
      })
  }

  getPeople(id) {
    return $api.get(`humans/${id}`, {
      params: {
        include: 'country,region,city,organizations,priority_organization,event_position,user,user.amocrm_user',
      },
      headers: authHeader(),
    })
  }

  createPeople(data) {
    return $api.post('humans', data, {
      headers: authHeader(),
    })
  }

  deletePeople(id, last) {
    return $api
      .delete('humans/' + id, {
        headers: authHeader(),
      })
      .then(() => {
        if (last) {
          this.getAllPeoples()
          Vue.$toast.error(messages.deletePeople)
        }
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  deleteItems(permanent = false, type) {
    let newParams = {}
    let data = {}
    if (permanent) newParams['force-delete'] = 1

    data[`id${type !== 'peoples' ? 's' : ''}`] = store.state[type].selectedItemsForDelete

    return $api.delete(type === 'peoples' ? 'humans' : 'organizations', {
      params: newParams,
      headers: authHeader(),
      data,
    })
  }

  restoreItems(name) {
    return $api.post(
      name === 'peoples' ? 'restore-humans' : 'restore-organizations',
      {
        ids: store.state[name].selectedItemsForDelete,
      },
      {
        headers: authHeader(),
      }
    )
  }

  updatePeople(id, data, last) {
    return $api
      .patch('humans/' + id, data, {
        headers: authHeader(),
      })
      .then(async () => {
        if (last) {
          await this.getAllPeoples()
          Vue.$toast.success(messages.updatePeople)
        }
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  deleteCompany(id, last) {
    return $api
      .delete('organizations/' + id, {
        headers: authHeader(),
      })
      .then(() => {
        if (last) this.getAllCompanies()
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  deleteCompanies(permanent = false) {
    let newParams = {}
    if (permanent) newParams['force-delete'] = 1

    return $api.delete('organizations', {
      params: newParams,
      headers: authHeader(),
      data: {
        id: store.state.companies.selectedItemsForDelete,
      },
    })
  }

  updateCompany(id, data, last) {
    return $api
      .patch('organizations/' + id, data, {
        headers: authHeader(),
      })
      .then(() => {
        if (last) this.getAllCompanies()
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  getCompany(id) {
    return $api.get('organizations/' + id, {
      headers: authHeader(),
      params: {
        include: 'city,region,country,humans,organization_ownership',
      },
    })
  }

  getPositions() {
    return $api
      .get('human-positions', {
        headers: authHeader(),
      })
      .then(async r => {
        return r.data.data
      })
      .catch(e => {
        errorHandler(e)
        return e
      })
  }
}

export default new PeopleAndCompaniesService()
